import styled from "styled-components";
import BackgroundTexture from "../components/shared/sub/BackgroundTexture";

import {
  Color,
  colors,
  colorsRGB,
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  assets,
  IMAC_BREAKPOINT,
} from "./constants";
import {
  AlignItems,
  AlignSelf,
  Dimensions,
  FlexDirection,
  FontFam,
  JustifyContent,
  Overflow,
  PDisplayType,
  FlexWrap,
  Cursor,
  FontWeight,
  WhiteSpace,
} from "./types";
import Image from "../components/shared/image";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: ${props => props.zIndex ?? 3};
  left: 0;
  top: 0;
  background-color: ${colorsRGB.black(0.5)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  fontFamily?: FontFam;
  noMargin?: boolean;
  marginTop?: number;
  marginBottom?: number;
  width?: number;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeight;
  opacity?: number;
  isMobileStyling?: boolean;
  display?: PDisplayType;
  whiteSpace?: WhiteSpace;
  cursor?: Cursor;
  padding?: number;
}>`
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  font-family: ${props => props.fontFamily ?? "regular"};
  font-size: ${props => props.fontSize ?? 14}px;
  line-height: ${props => props.lineHeight ?? 18}px;
  margin-top: ${props => props.marginTop ?? 20}px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ padding }) => padding && `padding: ${padding}px;`}
  ${({ display }) => display && `display: ${display};`}
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  background-color: transparent;
  font-weight: ${props => props.fontWeight ?? "normal"};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}

  ${({ isMobileStyling }) =>
    isMobileStyling &&
    `@media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      width: 80%;
      text-align: center;
      margin: auto;
  }`}
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
}>`
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  font-family: "regular";
  ${({ underlined }) =>
    underlined ? "text-decoration: underline;" : "text-decoration: none;"}

  ${({ bold }) => bold && "font-weight:500;"}

  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
      color: ${hoverColor};
    }`}
`;

export const H1 = styled.h1<{
  fontFamily?: FontFam;
  noMargin?: boolean;
  fontWeight?: string;
  fontSize?: number;
  color?: string;
}>`
  ${({ color }) => (color ? `color: ${color}` : `color: black`)};
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  color: #fff;
  font-family: ${props => props.fontFamily ?? "extraBold"};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "500")};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
`;

export const H2 = styled.h2<{
  fontFamily?: FontFam;
  width?: string;
  fontWeight?: string;
  fontSize?: number;
  color?: string;
  textAlign?: string;
}>`
  ${({ color }) => (color ? `color: ${color};` : "color: black;")}
  font-family: ${props => props.fontFamily ?? "extraBold"};
  ${({ width }) => width && `width: ${width};`}
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "500")};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`;

export const H3 = styled.h3<{ fontFamily?: FontFam; color: string }>`
  color: ${props => props.color ?? "black"};
  font-family: ${props => props.fontFamily ?? "extraBold"};
  font-weight: 700;
  margin: 0px;
  line-height: 20px;
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  dimensions?: Dimensions;
  opacity?: number;
  shouldShowMobile?: boolean;
}>`
  border-left: 1px solid
    ${props => (props.color ? colors[props.color] : colors.darkGreen)};
  height: ${props => props.dimensions?.height ?? `85px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldShowMobile }) =>
      shouldShowMobile ? "display: block;" : "display: none;"}
  }
`;

export const Separator = styled.div<{
  color?: Color;
  dimensions?: Dimensions;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
  border-bottom: ${props => props.thickness ?? 2}px solid
    ${props => (props.color ? colors[props.color] : colors.darkGreen)};
  ${({ dimensions }) =>
    dimensions && dimensions.width && `width: ${dimensions.width ?? "100px"};`}
  height: ${props => props.dimensions?.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  dimensions?: Dimensions;
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: FlexDirection;
  flex?: number | string;
  flexGrow?: number;
  flexWrap?: FlexWrap;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  alignSelf?: AlignSelf;
  borderRadius?: number;
  overflow?: Overflow;
  padding?: string;
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  mobilePadding?: string;
  mobileJustifyContent?: JustifyContent;
  mobileDimensions?: Dimensions;
  tabletDimensions?: Dimensions;
  mobileMargin?: string;
  tabletMargin?: string;
  width?: string;
  height?: string;
  opacity?: number;
  margin?: string;
  cursor?: Cursor;
  reverseDirection?: boolean | null;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  widthSize?: "default" | null;
  gap?: number;
}>`
  ${({ widthSize }) =>
    widthSize === "default" &&
    `
    width: 80%;
    max-width: 1000px;
    margin-left:auto;
    margin-right:auto;
  `}
  display: flex;
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ paddingVertical }) =>
    paddingVertical &&
    `padding: 0px ${paddingVertical}px 0px ${paddingVertical}px;`}
    ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    `padding: ${paddingHorizontal}px 0px ${paddingHorizontal}px 0px;`}
  ${({ dimensions }) =>
    dimensions && dimensions.height && `height: ${dimensions.height};`}
  ${({ dimensions }) =>
    dimensions && dimensions.width && `width: ${dimensions.width};`}
  width: 80%;
  ${({ img }) => img && `background-image: url(${img});`}
  flex-direction: ${props => props.flexDirection ?? "initial"};
  justify-content: ${props => props.justifyContent ?? "initial"};
  align-items: ${props => props.alignItems ?? "initial"};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) =>
    backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  align-self: ${props => props.alignSelf ?? "initial"};
  overflow: ${props => props.overflow ?? "initial"};
  border-radius: ${props => props.borderRadius ?? 0}px;
  background-size: cover;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `width: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ gap }) => gap && `gap: ${gap}px;`}


  ${({ reverseDirection }) =>
    reverseDirection && `flex-direction: row-reverse;`}

    ${({ showOnTablet }) => showOnTablet && `display: none;`}



  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMobileColumn }) =>
      isMobileColumn &&
      `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}
    justify-content: ${props => props.mobileJustifyContent ?? "initial"};

    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding}`}

    ${({ tabletDimensions }) =>
      tabletDimensions &&
      tabletDimensions.height &&
      `height: ${tabletDimensions.height};`}
  ${({ tabletDimensions }) =>
      tabletDimensions &&
      tabletDimensions.width &&
      `width: ${tabletDimensions.width};`}

      ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
      ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
      ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`}
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) =>
      mobileDimensions &&
      mobileDimensions.height &&
      `height: ${mobileDimensions.height};`}
    ${({ mobileDimensions }) =>
      mobileDimensions &&
      mobileDimensions.width &&
      `width: ${mobileDimensions.width};`}

      ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
      ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  width?: string;
  margin?: string;
  repeat?: number;
}>`
  display: grid;
  grid-template-columns: repeat(${props => props.repeat ?? 4}, 1fr);

  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Pill = styled(Container)<{ color?: Color; activeColor?: Color }>`
  padding: 5px 10px 5px 10px;
  background-color: ${props =>
    props.color ? colors[props.color] : colors.lightBiege};
  border-radius: 40px;
  margin: 5px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.6;
  }
`;

export const TextButton = styled(P)<{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  lineHeight?: number;
  margin?: string;
}>`
  line-height: ${props => props.lineHeight ?? 18}px;
  cursor: pointer;
  margin: 0;
  user-select: none;
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  color: ${colors.avocado};
  color: ${props => (props.color ? colors[props.color] : colors.avocado)};
  &:hover {
    color: ${props =>
      props.hoverColor ? colors[props.hoverColor] : colors.darkGreen};
  }
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${props => props.space ?? 10}px;
`;

export const BackGroundTexture = styled(BackgroundTexture)``;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  picture img {
    object-fit: contain !important;
  }
`;

export const Section = styled.div<{ noImage: boolean }>`
  width: 80%;
  margin: 80px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
  ${({ noImage }) => noImage && `grid-template-columns: repeat(1,1fr);`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: block;
    margin: 20px auto;
    width: 90%;
  }
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 20px auto;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 300px;
  }

  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    max-width: 100%;
  }
`;

export const SectionImg = styled(Image)`
  width: 100%;
  height: auto;
  margin: 0 auto;
  object-fit: contain;
`;

export const TextWrapper = styled.div`
  display: inline-grid;
  align-content: center;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 auto 6px auto;
  }
`;

export const BackLink = styled(A)`
  display: block;
  color: ${colors.green};
  width: 60%;
  margin: 0 auto 20px auto;
`;

export const ArticleTitle = styled(H1)`
  width: 60%;
  margin: 0 auto 20px auto;
  color: ${colors.black};
`;
