import React, { ReactNode } from "react";
import { createGlobalStyle } from "styled-components";

import Header from "./Header";
import Footer from "./Footer";
interface Props {
  children: ReactNode;
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
    font-family: "regular";
  }

  p {
    font-size: 16px !important;
  }

  a {
    cursor: pointer;
  }

  img {
        display: block;
        max-width: 100%;
        height: auto;
        width: 100%;
        &[data-loading="true"] {
          position: absolute;
          top: 0 !important;
          left: 0 !important;
          height: 0 !important;
          width: 0 !important;
        }
      }
`;

function Layout(props: Props) {
  const { children } = props;
  const checkForNews =
    props.location.pathname.includes("/news/") &&
    props.location.pathname !== "/news/";
  const checkForEvents =
    props.location.pathname.includes("/event/") &&
    props.location.pathname !== "/event/";

  return (
    <div>
      <GlobalStyle />
      <Header staticBackground={checkForNews || checkForEvents} />
      {children}
      <Footer />
    </div>
  );
}

export default Layout;
