import { mergeClassNames } from "@util/helpers";

import React from "react";
import { SanityImage } from "sanity-image";

import styled from "styled-components";

type Props = {
  /** The data object containing information and metadata about the image. */
  data: Queries.Maybe<Queries.SanityImageWithMeta> | undefined;
  /**
   * Specifies the loading behavior of the image.
   * It can either be "lazy" (loads the image when it's near the viewport) or "eager" (loads the image immediately).
   */
  loading?: "lazy" | "eager";
  /**
   * The width used for generating the sizes attribute. The image is responsive, so this does not affect the actual width of the image.
   */
  width?: number;
  className?: string;
  /** Disable the lqip data to render a preview image */
  disablePreview?: boolean;
  /** Overwrite the alt text from Sanity */
  alt?: string;
  /** Overwrite the image asset id from Sanity */
  id?: string;
  showFallback?: boolean;
};

interface RenderedImgProps extends Props {
  type?: "mobile" | "desktop";
  imgData: {
    asset: Queries.SanityImageAsset;
    hotspot: Queries.Maybe<Queries.SanityImageHotspot>;
    crop: Queries.Maybe<Queries.SanityImageCrop>;
    id: Queries.Maybe<string>;
  };
}

const PROJECT_ID = process.env.GATSBY_SANITY_PROJECT_ID || "";
const DATASET = process.env.GATSBY_SANITY_DATASET || "";
const BASE_URL = `https://cdn.sanity.io/images/${PROJECT_ID}/${DATASET}/`;

const RenderedImage = ({
  className,
  loading = "lazy",
  alt,
  disablePreview,
  data,
  width,
  imgData,
  type,
  showFallback,
  ...rest
}: RenderedImgProps) => {
  const classes = mergeClassNames(
    "image",
    className,
    type && `display-${type}`
  );
  const calculateMaxWidth = data?.maxWidth ? data?.maxWidth : undefined;
  const getAlign = (side: string) =>
    [side, "center"].includes(data?.position ?? "") ? "auto" : undefined;

  const maxWidthStyle = data?.maxWidth
    ? {
        maxWidth: calculateMaxWidth,
        marginLeft: getAlign("end"),
        marginRight: getAlign("start"),
      }
    : {};

  const finalAltText = alt || data?.altText || "Image";

  return (
    //@ts-ignore
    <StyledImage
      {...imgData}
      baseUrl={BASE_URL}
      width={width}
      decoding="async"
      loading={loading}
      alt={finalAltText}
      title={finalAltText}
      style={{
        ...maxWidthStyle,
      }}
      className={classes}
      preview={disablePreview ? "" : imgData?.asset?.metadata?.lqip ?? ""}
      {...rest}
    />
  );
};

// Styles are defined in the global styles file

const Image = (props: Props) => {
  const { data, showFallback } = props;

  if (!data?.asset) {
    if (showFallback) return <></>;
    return null;
  }

  const mainImgData = {
    asset: data.asset,
    hotspot: data?.hotspot,
    crop: data?.crop,
    //@ts-expect-error Ref only for preview
    id: data.asset?._id ?? data.asset?._ref,
  };

  if (!mainImgData.id) return null;
  return (
    <>
      <RenderedImage {...props} imgData={mainImgData} type={"desktop"} />
    </>
  );
};

export default Image;

//@ts-ignore
const StyledImage = styled(SanityImage)`
  &[data-lqip="true"] {
    max-width: 100% !important;
  }
`;
