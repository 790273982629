// import wrapWithProvider from "./wrap-with-provider"
// export const wrapRootElement = wrapWithProvider
import ReactDOM from "react-dom/client";
import React from "react";
import Layout from "./src/components/shared/Layout";
import "./static/fonts/fonts.css";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function replaceHydrateFunction() {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
}
