export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;
export const STANDARD_SERVING_SIZE = 2;
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;

export const colors = {
  green: "#00A032",
  darkBlue: "#3268a8",
  white: "#fff",
  black: "#000000",
  blue: "#001654",
  red: "#d22630",
  grey: "#B2B0B0",
};

export const colorsRGB = {
  lightBlue: (opacity?: number) => `rgba(177, 204, 209, ${opacity ?? "0.5"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  lightBlack: (opacity?: number) => `rgba(68, 68, 68, ${opacity ?? "0.4"})`,
  darkGreen: (opacity?: number) => `rgba(49, 74, 55, ${opacity ?? "0.2"})`,
  tan: (opacity?: number) => `rgba(201,165,132, ${opacity ?? "0.2"})`,
};

export type Color = keyof typeof colors;

export const icons = {
  downArrow: require("../../static/assets/icons/down-arrow.svg"),
  cross: require("../../static/assets/icons/cross.svg"),
  hamburger: require("../../static/assets/icons/hamburger.svg"),
  submitArrow: require("../../static/assets/icons/submitArrow.svg"),
  submitArrowGreen: require("../../static/assets/icons/submitArrowGreen.svg"),
  submitArrowBlue: require("../../static/assets/icons/submitArrowBlue.svg"),
  rightArrow: require("../../static/assets/icons/rightArrow.svg"),
  leftArrow: require("../../static/assets/icons/leftArrow.svg"),
  playButton: require("../../static/assets/icons/playButton.svg"),
  openQuote: require("../../static/assets/icons/openQuote.svg"),
  closeQuote: require("../../static/assets/icons/closeQuote.svg"),
  fb: require("../../static/assets/icons/facebook.svg"),
  insta: require("../../static/assets/icons/instagram.svg"),
  youtube: require("../../static/assets/icons/youtube.svg"),
  downloadDark: require("../../static/assets/icons/download-dark.png"),
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

export const assets = {
  backTexture: require("../../static/assets/homeHeroTexture.png"),
  backTextureSvg: require("../../static/assets/homeHeroTexture.svg"),
  loadingGif: require("../../static/assets/waitIndicator.svg"),
};

export const VEGA_BIG_WIDGET_URL =
  "https://connect.vega.works/Content/csharp/events.aspx?rfD3x0k=0988F320-AF69-4AB3-B22E-C4050EC7B9DC&xlnse5=2594";

export const VEGA_SMALL_WIDGET_URL =
  "https://connect.vega.works/(S(rhi1ygg1r5kvitefhkenscpv))/Content/csharp/DonateFormAll.aspx?rfD3x0k=0988F320-AF69-4AB3-B22E-C4050EC7B9DC&xlnse5=2364";
