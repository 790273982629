import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import styled from "styled-components";

import { A, Container, BackGroundTexture } from "../../util/standard";
import { Link } from "../../util/types";
import {
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  icons,
  colors,
} from "../../util/constants";

interface Props {
  staticBackground?: boolean;
}

export default function Header(props: Props) {
  const [userScrolled, toggleUserHasScrolled] = useState(false);
  const [mobileMenuOpened, toggleMobileMenuOpened] = useState(false);

  const { allSanityHeaderMenu } = useStaticQuery(graphql`
    {
      allSanityHeaderMenu {
        edges {
          node {
            menu {
              url
              linkText
            }
            menuLogoDesktop {
              ...sanityImageWithMeta
            }
            menuLogoMobile {
              ...sanityImageWithMeta
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (props.staticBackground) {
      toggleUserHasScrolled(true);
      window.removeEventListener("scroll", userScrollCheck);
    } else {
      window.addEventListener("scroll", userScrollCheck);
      userScrollCheck();
    }
  });

  const linkNavigate = (linkUrl: string) => {
    navigate(linkUrl);
    toggleMobileMenuOpened(false);
  };

  const userScrollCheck = () => {
    if (window.scrollY >= 5) {
      toggleUserHasScrolled(true);
    } else {
      toggleUserHasScrolled(false);
    }
  };

  const openMobileMenu = () => {
    mobileMenuOpened
      ? toggleMobileMenuOpened(false)
      : toggleMobileMenuOpened(true);
  };

  const { menuLogoDesktop, menuLogoMobile, menu } =
    allSanityHeaderMenu.edges[0].node;

  return (
    <Wrapper>
      <GreyOverLay mobileMenu={mobileMenuOpened} />
      <StyledImgMobile
        mobileMenuOpened={mobileMenuOpened}
        src={menuLogoMobile?.asset?.url}
        onClick={() => navigate("/")}
        alt={menuLogoMobile.asset.title ?? "warriors-mobile-icon"}
      />
      <HeaderContainer mobileMenu={!mobileMenuOpened} bg={userScrolled}>
        <StyledBackGroundTexture
          textureVisible={userScrolled || mobileMenuOpened}
        />
        <StyledImg
          src={menuLogoDesktop?.asset?.url}
          onClick={() => navigate("/")}
          alt={menuLogoDesktop.asset.title ?? "warriors-desktop-icon"}
        />
        <BurgerIcon
          bg={userScrolled && !mobileMenuOpened}
          mobileMenuOpened={userScrolled && !mobileMenuOpened}
        >
          {!mobileMenuOpened && (
            <StyledBackGroundTexture textureVisible={userScrolled} />
          )}
          <StyledIcon
            onClick={() => openMobileMenu()}
            src={
              mobileMenuOpened
                ? "/assets/icons/cross.svg"
                : "/assets/icons/hamburger.svg"
            }
            alt="mobile-menu-icon"
          />
        </BurgerIcon>
        <LinksContainer>
          {menu.map((link: Link) => {
            return (
              <StyledLink
                key={link.linkText}
                onClick={() => linkNavigate(link.url ?? "")}
              >
                {link.linkText}
              </StyledLink>
            );
          })}
          <ButtonLink onClick={() => linkNavigate("/donate")} bgEnabled={false}>
            Donate
          </ButtonLink>
        </LinksContainer>
      </HeaderContainer>
    </Wrapper>
  );
}

const HeaderContainer = styled(Container)<{ bg: boolean; mobileMenu: boolean }>`
  transition: background-color 1s;
  ${({ bg }) => bg && `background-color: ${colors.blue};`};
  position: fixed;
  width: 100%;
  height: 110px;
  z-index: 4;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 100vh;
    width: 65%;
    right: 0px;
    background-color: ${colors.blue};
    /* ${({ mobileMenu }) => mobileMenu && "transition: right 0.2s;"} */
    ${({ mobileMenu }) =>
      mobileMenu && "right: -65%; transition: right 0.2s; z-index: 4;"}
    transition: right 0.2s;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const StyledBackGroundTexture = styled(BackGroundTexture)<{
  textureVisible: boolean;
}>`
  opacity: 0;
  ${({ textureVisible }) => textureVisible && `opacity: 1;`}
  transition: all 0.2s;
`;

const GreyOverLay = styled.div<{ mobileMenu: boolean }>`
  display: none;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 1;
    z-index: 3;
    ${({ mobileMenu }) => mobileMenu && "display:block; opacity: 0.5;"}
    transition: opacity 0.2s;
  }
`;

const LinksContainer = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: inline-grid;
    justify-content: flex-start;
    height: 450px;
    padding-top: 150px;
    width: 70%;
    margin: 0 auto;
  }
`;
const StyledLink = styled(A)`
  padding: 20px 0;
  width: max-content;
  margin: 20px;
  text-align: center;
  color: white;
  cursor: pointer;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    // width: 100%;
    text-align: left;
    padding: 10px 0;
    margin: 10px 0;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 5px 0;
    margin: 5px 0;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.5);
  }
  transition: color 0.2s;
`;

const StyledImg = styled.img`
  height: 110px;
  width: 270px;
  padding: 0px 0px 0px 30px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledImgMobile = styled.img<{ mobileMenuOpened: boolean }>`
  display: none;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: block;
    position: fixed;
    height: 60px;
    width: 60px;
    top: 10px;
    left: 15px;
    z-index: 5;

    ${({ mobileMenuOpened }) => mobileMenuOpened && `z-index: 2;`};
  }
`;

const BurgerIcon = styled.div<{
  bg: boolean;
  textureVisible?: boolean;
  mobileMenuOpened: boolean;
}>`
  display: none;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: block;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    ${({ bg }) => bg && `background-color: ${colors.blue};`};
    ${({ mobileMenuOpened }) =>
      mobileMenuOpened && `background-color: ${colors.blue}`};
    height: 80px;
    z-index: 1;
  }
`;

const StyledIcon = styled.img`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: block;
    position: fixed;
    right: 30px;
    top: 30px;
    width: 23px;
  }
`;
const ButtonLink = styled(A)<{ bgEnabled: boolean }>`
  margin-right: 30px;
  margin-left: 20px;
  text-align: center;
  color: white;
  background-color: ${colors.green};
  border: 1px solid ${colors.green};
  padding: 10px 25px;
  cursor: pointer;
  font-family: bold;
  text-transform: uppercase;
  ${({ bgEnabled }) => bgEnabled && `background-color: ${colors.blue};`}
  ${({ bgEnabled }) => bgEnabled && "border: 1px solid white;"}
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 20px 0;
    background-color: ${colors.blue};
    border: 1px solid white;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 10px 0;
  }

  &:hover {
    ${({ bgEnabled }) =>
      !bgEnabled &&
      `
      background-color:transparent;
      color: ${colors.white};
      border: 1px solid ${colors.white};
      `}
    ${({ bgEnabled }) =>
      bgEnabled &&
      `
      background-color: ${colors.green};
      color: ${colors.white};
      border: 1px solid ${colors.green};
      `}
  }
  transition: all 1s;
`;
